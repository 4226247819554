import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfoModal from "./photos/components/infoModal";
import config from "../config";
import PoweredBy from "./photos/components/poweredBy";

function Home() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();



  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleStartClick = () => {
    if (username.trim().length < 3) {
      setError(true);
      return;
    }
    const randomString = generateRandomString(6);
    localStorage.setItem("Name", username);
    localStorage.setItem("Id", randomString);
    navigate("/uploadPhotos");
  };

  return (
    <div className="h-[100dvh] overflow-hidden w-screen relative flex items-center justify-center text-white p-5 font-della">
      <div className="flex flex-col gap-6 absolute top-16 text-center">

        <h1 className="text-3xl">Dobrodošli!</h1>
        <div >
          <h1 className="text-3xl">{config.name}</h1>
          <h1 className="text-3xl">{config.date}</h1>
        </div>
      </div>


      <div className="flex flex-col gap-6 w-full ">
        <div>
          <input
            type="text"
            placeholder="Unesite ime i prezime"
            aria-label="Unesite ime i prezime"
            value={username}
            className="p-2 w-full rounded-md text-elegance text-center"
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                handleStartClick()
              }
            }}
          />

          {error && <p className="text-red-500 text-center">Ime i prezime su obavzni</p>}
        </div>

        <button
          className="w-full p-2  rounded-md border border-white"
          onClick={handleStartClick}
        >
          Započni
        </button>
      </div>

      <InfoModal />
      <PoweredBy />


    </div>
  );
}

export default Home;
