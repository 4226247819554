const config = {
    name: 'Jelena & Marko',
    date: '26.10.2024.',
    subdomain: 'jelenaimarko26102024',
    password: 'jelena454',
    maxImage: 25
};


// PROMIJENI INDEX TITLE

export default config;